<template>
  <div id="contents" class="product_availability">
    <div class="contents_head">
      <h2>제품생산 가능여부</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: true }">
            <a>제품생산 가능여부</a>
          </li>
        </ul>
      </div>

      <product-availability-form></product-availability-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import ProductAvailabilityForm from '@/views/forms/Plan/10/ProductAvailabilityForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    ProductAvailabilityForm,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromGumsanManagementPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToGumsanManagementPage',
    }),
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      // this.$store.commit('InitGumsanManagementPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import './korea_doctor.scss';
</style>
